<template>
  <div>
    <div
      class="modal fade"
      tabindex="-1"
      role="dialog"
      :class="{ show: showModal }"
      :style="{ display: showModal ? 'block' : 'none' }"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Registrera</h5>
          </div>
          <div class="modal-body">
            <form @submit.prevent="submitRegistration">
              <div class="form-group">
                <label for="firstName">Förnamn</label>
                <input
                  type="text"
                  class="form-control"
                  id="firstName"
                  v-model="registration.firstName"
                  required
                />
              </div>
              <div class="form-group">
                <label for="lastName">Efternamn</label>
                <input
                  type="text"
                  class="form-control"
                  id="lastName"
                  v-model="registration.lastName"
                  required
                />
              </div>
              <div class="form-group">
                <label for="email">Email</label>
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  v-model="registration.email"
                  required
                />
              </div>
              <div class="form-group">
                <label for="birthDate">Födelsedatum (ÅÅMMDD)</label>
                <input
                  type="text"
                  class="form-control"
                  id="birthDate"
                  v-model="registration.birthDate"
                  required
                />
              </div>
              <div class="form-group">
                <label for="employer">Anställd av</label>
                <input
                  type="text"
                  class="form-control"
                  id="employer"
                  v-model="registration.employedBy"
                />
              </div>
              <div class="form-group">
                <label for="company">Företag</label>
                <select
                  class="form-control"
                  id="company"
                  v-model="registration.company"
                >
                  <option v-for="company in companies" :key="company">
                    {{ company }}
                  </option>
                </select>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeModal">
              Stäng
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="submitRegistration"
            >
              Skicka
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios';

export default {
  name: "RegistrationFormComponent",
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      registration: {
        firstName: "",
        lastName: "",
        email: "",
        birthDate: "",
        employedBy: "",
        company: "Show all...",
      },
      companies: [
        "Show all...",
        "GRYAAB",
        "Göteborg Energi",
        "Göteborgs Hamn AB",
        "Inter Terminals Sweden",
        "Johans it",
        "Kosan Gas Sverige",
        "Maflobe",
        "Nordic Storage",
        "Nynäs Refining",
        "ODEC Oljelossning",
        "Petrol trading",
        "Preem AB",
        "Saybolt Sweden",
        "Scandinavian Tank Storage",
        "Scanlube",
        "St1 Raff",
        "St1 Terminal (Depå)",
        "Stena Oil AB",
        "Stena Recycling",
        "Swedegas",
      ],
    };
  },
  methods: {
    closeModal() {
      this.$emit("close-modal");
    },
    async submitRegistration() {
      /*
      try {
        const response = await axios.post('http://localhost:5142/Registration', this.registration);
        console.log(response.data);
        // You can add further actions based on response
        this.closeModal();
      } catch (error) {
        console.error('There was an error!', error.response ? error.response.data : error.message);
      }
      */

      alert("Registration submitted!");
      console.log(JSON.stringify(this.registration));
      this.closeModal();
    },
  },
};
</script>

<style scoped>
.modal.show {
  display: block;
}

.modal-backdrop {
  display: none;
}
</style>
