<template>
  <span class="control-label small h6">{{ title }}</span>
  <ItemComponent
    v-for="(course, index) in courses"
    :key="index"
    :course-id="course.id"
    :status="course.status"
    :date="course.date"
    :title="course.title"
    :description="course.description"
    :imageUrl="course.imageUrl"
    :buttonText="course.buttonText"
  />
</template>

<script>
import ItemComponent from "./ItemComponent.vue";
import axios from "axios";

export default {
  name: "CoursesComponent",
  components: {
    ItemComponent,
  },
  props: {
    jsonPath: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      courses: [],
    };
  },
  created() {
    this.loadCourses();
  },
  methods: {
    async loadCourses() {
      try {
        const response = await axios.get(this.jsonPath);
        this.courses = response.data;
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    },
  },
};
</script>

<style scoped>
.courses-list {
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 5px;
}
</style>
