<template>
  <div class="container-fluid bg-white bg-gradiant shadow p-2 mt-4">
    <div class="container p-2">
      <div class="row d-flex justify-content-end align-items-center text-end">
        <div class="col-12 p-2 d-flex flex-column align-items-end">
          <img
            :src="require('../../assets/maflobe.jpg')"
            alt="Maflobe Logo"
            class="footer-logo mb-2"
          />
          <p class="mb-0">
            Website: <a>0700-00 00 00</a><br />
            E-mail: <a>info@maflobe.se</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>

<style scoped>
.footer-logo {
  max-width: 150px;
}
</style>
