import { jwtDecode } from "jwt-decode";
import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import CourseView from "../views/CoursesView.vue";
import chapterView from "../views/ChapterView.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
    meta: { requiresAuth: false },
  },
  {
    path: "/Kurser",
    name: "CourseView",
    component: CourseView,
    meta: { requiresAuth: true },
  },
  {
    path: "/Kurser/kapitel",
    name: "chapterView",
    component: chapterView,
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// check if token is expired
function isExpired() {
  const token = localStorage.getItem("token");
  if (token) {
    const { exp } = jwtDecode(token);
    return Date.now() > exp * 1000;
  }
  return false;
}
// go to the route if token is not expired or if the route does not require authentication
router.beforeEach((to, from, next) => {
  if (!to.name) {
    alert("Sidan finns inte");
    next({ name: "Home" });
  } else {
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    if (requiresAuth && isExpired()) {
      alert("Du behöver logga in för att se denna sida");
      next({ name: "Home" });
    } else {
      next();
    }
  }
});

export default router;
