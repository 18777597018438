<template>
  <div class="step-container">
    <div class="step-circle">{{ stepNumber }}</div>
    <div class="step-content">
      <span v-html="content"></span>
      <div class="step-slot mt-2"><slot></slot></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StepComponent",
  props: {
    stepNumber: {
      type: Number,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.step-container {
  display: flex;
  align-items: flex-start;
}

.step-circle {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  margin-right: 1rem;
  flex-shrink: 0;
}
</style>
