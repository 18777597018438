<template>
  <div class="container-fluid bg-white shadow-sm">
    <div class="container">
      <nav class="navbar navbar-expand-lg navbar-light">
        <a class="navbar-brand d-flex align-items-center" href="#">
          <img
            src="../../assets/GöteborgHamn.jpg"
            alt="Göteborgs Hamn Logo"
            class="brand-logo"
          />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav me-auto">
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="#">Startsida</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Kurser</a>
            </li>
          </ul>
          <ul class="navbar-nav ms-auto">
            <li class="nav-item">
              <a class="nav-link" href="#">Mitt konto</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Logga ut</a>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {{ language }}
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <a class="dropdown-item" href="#" @click="changeLanguage('Svenska')">Svenska</a>
                </li>
                <li>
                  <a class="dropdown-item" href="#" @click="changeLanguage('English')">English</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderComponent",
  data() {
    return {
      language: "Svenska",
    };
  },
  methods: {
    changeLanguage(lang) {
      this.language = lang;
      console.log(`Language changed to: ${lang}`);
    },
  },
};
</script>

<style scoped>
.brand-logo {
  height: 3rem;
  width: auto;
  margin-left: 1rem;
}

/* .navbar-nav .nav-item .nav-link:hover {
  color: #024082;
  background-color: #f1f1f1;
  border-radius: 0.25rem;
}

.navbar-nav .nav-item .nav-link.active {
  color: #6c757d;
}

.navbar-nav .dropdown-menu {
  min-width: 8rem;
}

.navbar-nav .dropdown-item:hover {
  color: #024082;
  background-color: #f1f1f1;
} */
</style>