<template>
  <h2>Kursinformation</h2>
  <p>
    Denna kurs är för alla som arbetar permanent eller tillfälligt i
    Energihamnen i Göteborg.
  </p>
  <p>
    För att kunna gå denna E-learning kurs på Internet krävs en kurskod som man
    får av sin arbetsgivare alternativt sin uppdragsgivare.
  </p>
</template>

<script>
export default {
  name: "InformationComponent",
};
</script>

<style scoped></style>
