<template>
  <div class="container-fluid">
    <div class="row">
      <h2 class="form-title">Logga in</h2>
    </div>
    <div class="row">
      <div class="col">
        <input
          type="text"
          class="form-control"
          id="firstName"
          placeholder="Förnamn"
          v-model="formData.firstName"
        />
      </div>
      <div class="col">
        <input
          type="text"
          class="form-control"
          id="lastName"
          placeholder="Efternamn"
          v-model="formData.lastName"
        />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <input
          type="text"
          class="form-control"
          id="birthDate"
          placeholder="Födelsedatum (ÅÅMMDD)"
          v-model="formData.birthDate"
        />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <input
          type="text"
          class="form-control"
          id="courseCode"
          placeholder="Kurskod"
          v-model="formData.courseCode"
        />
      </div>
      <div class="col-auto">
        <button type="submit" class="btn btn-primary" @click="submitForm">
          Starta kurs
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { ref } from "vue";
import axios from "axios";

export default {
  name: "FormComponent",
  setup() {
    const router = useRouter();
    const formData = ref({
      firstName: "",
      lastName: "",
      birthDate: "",
      courseCode: "",
    });

    const submitForm = async () => {
      try {
        const response = await axios.post(
          "http://localhost:5142/api/Auth/login",
          formData.value
        );
        console.log("Form submitted:", response.data);

        // if response is successful, redirect to the logged in view
        if (response.status === 200) {
          router.push("/loggedIn");
          // save token to local storage
          localStorage.setItem("token", response.data);
        }
      } catch (error) {
        alert("Felaktiga uppgifter, försök igen!");
      }
    };

    return {
      formData,
      submitForm,
    };
  },
};
</script>

<style scoped>
.form-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.row {
  width: 100%;
  margin-bottom: 15px;
}

.col {
  flex: 1;
}

.col-auto {
  flex: none;
  display: flex;
  align-items: end;
}
</style>
