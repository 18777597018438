<template>
  <HeaderComponent />
  <div class="container shadow-sm bg-white rounded p-3 mt-4 mb-4">
    <CoursesComponent :json-path="'/Data/courseStep.json'" :title="'KAPITEL'" />
  </div>
</template>
<script>
import HeaderComponent from "../components/LoggedIn/HeaderComponent.vue";
import CoursesComponent from "../components/LoggedIn/CoursesComponent.vue";

export default {
  name: "ChapterView",
  components: {
    HeaderComponent,
    CoursesComponent,
  },
};
</script>

<style scoped></style>
