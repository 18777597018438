<template>
  <div class="card mb-3">
    <div class="card-header">
      <span :class="statusClass">{{ status }}</span>
      <span class="course-date ms-3">{{ date }}</span>
    </div>
    <div class="card-body">
      <div class="row .d-none">
        <div class="col-md-8 d-flex flex-column justify-content-center">
          <div class="card-body">
            <h5 class="card-title">{{ title }}</h5>
            <p class="card-text">{{ description }}</p>
            <button
              v-if="showButton"
              @click="navigateToCourse"
              class="btn btn-custom"
            >
              {{ buttonText }}
            </button>
          </div>
        </div>
        <div class="col-md-4 d-flex align-items-center justify-content-center">
          <div class="image-container">
            <img
              v-if="imageUrl"
              :src="imageUrl"
              alt="Course Image"
              class="course-image img-fluid p-2"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useRouter } from "vue-router";

export default {
  name: "CourseComponent",
  props: {
    status: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    imageUrl: {
      type: String,
      default: "",
    },
    buttonText: {
      type: String,
      default: "TILL KURS",
    },
  },
  setup(props) {
    const router = useRouter();

    const statusClass = computed(() => {
      if (props.status.toLowerCase() === "ej godkänd") {
        return "text-failed";
      } else if (
        props.status.toLowerCase() === "godkänd" ||
        props.status.toLowerCase() === "kurs godkänd"
      ) {
        return "text-success";
      } else if (props.status.toLowerCase().includes("startad")) {
        return "text-primary";
      }
      return "";
    });

    const showButton = computed(() => {
      return true;
    });

    const navigateToCourse = () => {
      console.log("Navigating to course...");
      router.push("/LoggedIn/Course");
    };

    return {
      statusClass,
      showButton,
      navigateToCourse,
    };
  },
};
</script>

<style scoped>
.text-failed {
  color: red;
}
.text-success {
  color: green;
}

.text-primary {
  color: blue;
}

.course-image {
  object-fit: contain;
  max-height: 200px;
}

.btn-custom {
  background-color: #024082;
  border-color: #024082;
  color: white;
  border-radius: 2px;
}
</style>
