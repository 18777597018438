<template>
  <div class="container-fluid">
    <div class="row mb-4 mt-4">
      <div class="col">
        <h2 class="instructions-title">Hur gör man?</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <StepComponent
          :stepNumber="1"
          content="Är du ny användare måste du först registrera dig. Klicka på den blå knappen <strong>Registrera</strong> och mata in dina uppgifter. Vid registrering accepteras registervård enligt GDPR EU 2016-679."
        >
          <button class="btn btn-green" @click="$emit('show-registration')">
            Registrera
          </button>
        </StepComponent>
      </div>
      <div class="col-md-4">
        <StepComponent
          :stepNumber="2"
          content="Logga in på kursen genom att: <ul><li>Skriv förnamn och efternamn</li><li>Födelsedatum (ååmmdd)</li><li>Kurskod</li></ul>Klicka sedan på den blå knappen <strong>Starta kurs</strong>."
        />
      </div>
      <div class="col-md-4">
        <StepComponent
          :stepNumber="3"
          content="Starta utbildningen genom att klicka på ett kapitel. När kapitel 1-7 är klara är du godkänd och finns registrerad i databasen <strong>www.grontkort</strong>. LYCKA TILL!"
        />
      </div>
    </div>
  </div>
</template>

<script>
import StepComponent from "./StepComponent.vue";

export default {
  name: "InstructionsComponent",
  components: {
    StepComponent,
  },
};
</script>

<style scoped>
.step-container {
  margin-bottom: 0px;
}
</style>
